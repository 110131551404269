.workHistoryPane {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  gap: 20px;
  padding: 1%;
}

.workTitle {
  cursor: pointer;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.workTitle img {
  height: 50px;
  width: 50px;
}

.secondaryPoint li {
  margin-left: 2em;
}

.skillsPane {
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 100%;
  justify-content: center;
}

.skillsPaneRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.skillsCard {
  cursor: pointer;
  margin: 20px;
}

.skillsCardBody {
  padding: 5px;
}

.skillsCardBody img {
  height: 100px;
  width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.skillsModalBody {
  padding: 10px;
}

.offcanvas-start {
  width: 900px !important;
}

@media only screen and (max-width: 815px) {
  .skillsPane {
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .skillsPaneRow {
    flex-direction: column;
  }

  .skillsCard {
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    bottom: 0;
    position: relative;
  }
}
