.contactModalFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.contactModal div {
  width: 500px;
  height: 30%;
}

.contactModalFooter svg {
  height: 25px;
  width: 25px;
}
