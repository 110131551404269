@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  font-family: 'Quicksand', sans-serif;
  background-color: rgba(0, 0, 0, 0.9);
}

.welcome {
  display: flex;
  flex-direction: column;
  padding-right: 7%;
  padding-left: 7%;
  color: white;
  margin-bottom: 35%;
}

.typedName {
  order: 1;
  text-align: right;
  font-size: 30px;
}

.typedPrompt {
  order: 2;
  text-align: right;
  font-size: 16px;
}

/*  SVG icons 25x25 w/ 20px gap totalWidth: 75+40 115 */
.myLinks {
  order: 3;
  margin-top: 50%;
}

@media only screen and (min-width: 650px) {

  .welcome {
    padding-bottom: 15%;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }

  .typedName {
    order: 2;
    text-align: center;
    font-size: 38px;
  }

  .typedPrompt {
    order: 3;
    text-align: center;
    font-size: 28px;
  }

  .myLinks {
    margin-top: 0;
    order: 1;
  }
}
